<template>
    <div v-if='loading' class='text-center'>
        <ProgressSpinner style='margin-top: 10%' />
    </div>
    <div v-else-if='translation !== null'>
        <div class='grid'>
            <div class='col-12 md:col-4 text-center'>
                <Button label='Genera PDF' @click='generateReport' class='sticky'></Button>
                <br/>
                <div class='grid mt-3 sticky-cut p-fluid formgrid'>
                    <div class='col-6 pt-1'>Copyright Margin Top</div>
                    <div class='col-2 field'>
                        <InputNumber v-model="copyrightMT" />
                    </div>
                    <div class='col-2'>
                        <font-awesome-icon
                            class='pointer'
                            title='Page Break before first chapter'
                            :style='{color: cutBeforeFirstChapter ? "green" : "red"}'
                            :icon="['fas', 'cut']"
                            size='2x'
                            @click='cutBeforeFirstChapter = !cutBeforeFirstChapter'
                        />
                    </div>
                </div>
                <Accordion class='sticky_accordion'>
                    <AccordionTab :header='chapter.title' v-for='chapter in translation.chapters' :key='"chapter_edit_"+chapter["id"]'>
                        <Textarea
                            :ref="'textarea_'+chapter.id"
                            style='width: 100%; overflow: scroll'
                            :id='"c_t_"+chapter.id'
                            rows='15'
                            v-model='chapter.body'></Textarea>
                        <font-awesome-icon
                            class='pointer'
                            title='Page Break'
                            :style='{marginTop: "15px", color: "red"}'
                            :icon="['fas', 'cut']"
                            size='2x'
                            @click='execInsertPB(chapter.id)'
                        />
                    </AccordionTab>
                </Accordion>
            </div>
            <div class='col-12 md:col-8'>
                <vue3-html2pdf
                    :show-layout='true'
                    :float-layout='false'
                    :enable-download='false'
                    :preview-modal='true'
                    :filename="translation.slug+'_'+lang"
                    :html-to-pdf-options='pdfOptions'
                    :pdf-quality='2'
                    :manual-pagination='true'
                    pdf-format='a4'
                    pdf-orientation='portrait'
                    @progress='onProgress($event)'
                    @hasStartedGeneration='hasStartedGeneration()'
                    @beforeDownload="beforeDownload($event)"
                    @hasGenerated='hasGenerated($event)'
                    ref='html2Pdf'
                >
                    <template v-slot:pdf-content>
                        <div class='first-page'>
                            <div id='empty_top'></div>
                            <div id='book_title' :class='{[copyClass]: true}'>
                                {{ translation.title }}
                            </div>
                            <div id='catchline' v-html='subtitle' :class='{[copyClass]: true}'>
                            </div>
                            <div id='info' :class='{[copyClass]: true}'>
                                <div class='author'>{{ author }}</div>
                                <div class='publisher'>{{ publisher }}</div>

                            </div>
                            <div id='copyright' :class='{[copyClass]: true}' :style='copyrightStyle'>{{ copyright }}</div>
                        </div>
                        <div class='pages'>
                            <h2 class='p-mb-6'>{{ suggestions }}</h2>
                            <div v-html='translation.purpose'></div>

                            <h2  class='mt-6 p-mb-4'>{{authorTitle}}</h2>
                            <div v-html='authorDesc'></div>
                            <div class='spacer' v-html='"&nbsp;"'></div>
                            <span v-if='cutBeforeFirstChapter' class="footer">&nbsp;</span>

                            <!-- Capitoli -->
                            <section class="chapter" v-for='chapter in translation.chapters' :key='"chapter_"+chapter["id"]'>
                                <h2  class='p-mb-4'>{{chapter.title}}</h2>
                                <div v-html='chapter.body'></div>
                                <div class='html2pdf__page-break'></div>
                            </section>
                        </div>
                    </template>
                </vue3-html2pdf>
            </div>
        </div>

    </div>
    <div v-else>
        Non è stato possibile recuperare i dati del libro richiesto
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Notifications from '../../../../mixins/notifications';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/Books');
import ProgressSpinner from 'primevue/progressspinner';
import Vue3Html2pdf from 'vue3-html2pdf';

export default {
    data() {
        return {
            translation: null,
            original_title: '',
            authors: [],
            cutBeforeFirstChapter: false,
            copyrightMT: 0,
            insertText: '<span class="footer">&nbsp;</span>',
        };
    },
    mounted() {
        this.loadData(this.book_id);
    },
    components: {
        ProgressSpinner, Vue3Html2pdf,
    },
    props: {
        book_id: {
            type: String,
            required: true,
        },
        lang: {
            type: String,
            required: true,
        },
    },
    watch: {
        book_id(n) {
            this.loadData(n);
        },
        authToken(tok) {
            if (tok === '') {
                this.$router.push(('/login'));
            }
        },
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        copyrightStyle(){
            if (this.copyrightMT > 0){
                return {"margin-top": this.copyrightMT + "mm"};
            }
            return {};
        },
        pdfOptions(){
            return {
                margin: [0, 0],
                filename: this.translation.slug+'_'+this.lang,
                pagebreak: {
                    mode: ['css']
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                }
            };
        },
        subtitle() {
            if (this.original_title) return '&#8220;' + this.original_title + '&#8221;';
            return '';
        },
        author() {
            if (!this.authors.length) return '';
            const arr = this.authors.map(el => el.name);
            return arr.join(', ');
        },
        authorDesc() {
            if (!this.authors.length) return '';
            const arr = this.authors.map(el => {
                if (el.translation && el.translation.description)
                    return el.translation.description.replace('<strong>', '<strong class="bold">');
                return el.name;
            });
            return arr.join(' ');
        },
        authorTitle(){
            if (this.lang === 'it') {
                return "L'autore del libro: ";
            }
            return "The author: ";
        },
        copyright() {
            if (this.lang === 'it') {
                return '© Tutti i diritti sono riservati a 4books Ltd. / Copyright 4books Ltd.';
            }
            if (this.lang === 'en') {
                return '© All right reserved to UP srl. / Copyright UP srl.';
            }
            return '';
        },
        suggestions() {
            if (this.lang === 'it') {
                return 'Tanti suggerimenti utili per:';
            }
            return 'Useful advices for:';
        },
        publisher() {
            let p = (this.translation.publisher && this.translation.publisher.name) ? this.translation.publisher.name : '';
            if (this.translation.year) {
                if (p) p += ', ';
                p += this.translation.year;
            }
            return p;
        },
        copyClass(){
            if (!this.translation) return "";
            if (!this.translation.title) return "";

            const wc = this.translation.title.trim().split(' ').length;
            if (wc < 4) return "medium";
            if (wc <= 5) return "large";
            if (wc <= 7) return "very_large";
            if (wc < 9) return "xl";

            return "xxl";
        }
    },
    mixins: [Notifications],
    methods: {
        ...mapActions(['fetchOne']),
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .get('pdf')
                .then(pdf => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(15)
                        pdf.setTextColor(10)
                        if (i === 1) continue;
                        pdf.text('' + (i-1) , (pdf.internal.pageSize.getWidth() * 0.92), (pdf.internal.pageSize.getHeight() - 6))
                    }
            }).save()
        },
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        onProgress() {
        },
        hasStartedGeneration() {
        },
        hasGenerated() {
        },
        resetData() {
            this.translation = null;
            this.original_title = '';
            this.authors = [];
        },
        execInsertPB(ch) {
            const insertText = this.insertText;
            if (!insertText.length) return;
            const textarea = this.$refs["textarea_"+ch][0].$el;
            const sentence = textarea.value;
            const len = sentence.length;
            let pos = textarea.selectionStart;
            if (pos === undefined) {
                pos = 0;
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            this.translation.chapters.forEach(chap => {
               if (chap.id === ch){
                   chap.body = before + insertText + after;
               }
            });

            this.$nextTick().then(() => {
                textarea.selectionStart = pos + insertText.length
            })
        },
        async loadData(id) {
            this.resetData();

            try {
                await this.fetchOne(id);
                if (!this.row['translations']) {
                    this.ShowError('ATTENZIONE', 'Questo libro non ha traduzioni');
                    return;
                }
                if (!this.row['translations'][this.lang]) {
                    this.ShowError('ATTENZIONE', 'Questo libro non ha traduzione in ' + this.lang);
                    return;
                }
                this.translation = this.row['translations'][this.lang];
                this.original_title = this.row['original_title'];
                this.authors = this.row['authors'];
            } catch (err) {
                this.ShowStandardError(err.response);
            }
        },
    },
};
</script>

<style lang='scss'>
@font-face {
    font-family: 'Lato Black';
    src: url('../../../../assets/fonts/Lato-Black.eot');
    src: url('../../../../assets/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
    url('../../../../assets/fonts/Lato-Black.woff2') format('woff2'),
    url('../../../../assets/fonts/Lato-Black.woff') format('woff'),
    url('../../../../assets/fonts/Lato-Black.ttf') format('truetype'),
    url('../../../../assets/fonts/Lato-Black.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Regular';
    src: url('../../../../assets/fonts/Lato-Regular.eot');
    src: url('../../../../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../../../assets/fonts/Lato-Regular.woff2') format('woff2'),
    url('../../../../assets/fonts/Lato-Regular.woff') format('woff'),
    url('../../../../assets/fonts/Lato-Regular.ttf') format('truetype'),
    url('../../../../assets/fonts/Lato-Regular.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Italic';
    src: url('../../../../assets/fonts/Lato-Italic.eot');
    src: url('../../../../assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
    url('../../../../assets/fonts/Lato-Italic.woff2') format('woff2'),
    url('../../../../assets/fonts/Lato-Italic.woff') format('woff'),
    url('../../../../assets/fonts/Lato-Italic.ttf') format('truetype'),
    url('../../../../assets/fonts/Lato-Italic.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

.bottom-fixed {
    position: fixed;
    bottom: 10px;
}

.cfix {
    position: fixed;
    top: 0;
    margin: auto;
}

footer {
    font-size: 9px;
    color: #f00;
    text-align: center;
}


/*


html, body {
    width: 210mm !important;
    background-color: white !important;
}
*/

.chapter {
    margin-bottom: 20mm;

}

.footer {
    page-break-after: always;
    margin-bottom: 20mm;
    display: block
}

.sticky {
    position: sticky;
    top: 10px;
}

.sticky-cut {
    position: sticky;
    top: 49px;
}

.sticky_accordion {
    position: sticky;
    top: 100px;
}

.pages {
    border-left: 5mm solid #3B49FF;
    padding: 20mm;
    font-family: "Lato Regular";
    font-size: 15pt;
    h2{
        font-family: "Lato Black";
        font-size: 20pt;
    }
    color: rgb(74, 74, 74);
    ul {
        li {
            margin-bottom: 4mm;
        }
    }
    .bold{
        font-family: "Lato Black";
    }
    .spacer{
        height: 20mm;
    }
}

.first-page {
    background-image: url("~@/assets/images/books/cover-pdf.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 297mm !important;
    width: 210mm !important;
    padding-left: 30mm;
    font-family: "Lato Black";
}

#empty_top {
    height: 90mm;
}

#copyright {
    margin-top: 40mm;
    color: white;
    margin-left: -10mm;
    font-size: 0.8em;
    &.xxl {
        margin-top: 10mm;
    }
    &.xl {
        margin-top: 10mm;
    }
    &.very_large {
        margin-top: 20mm;
    }
    &.large {
        margin-top: 30mm;
    }
}

.author {
    font-family: "Lato Regular";
}

.publisher {
    font-family: "Lato Italic";
}

#info {
    margin-top: 60mm;
    color: white;
    margin-left: -5mm;
    font-size: 14pt;
}

#catchline {
    font-family: "Lato Regular";
    color: white;
    font-size: 16pt;
    padding-top: 14mm;
    width: 105mm;
}

#book_title {
    /*border-bottom: 1px solid white;*/
    text-transform: uppercase;
    width: 105mm;
    color: white;
    line-height: 68px;
    font-size: 50pt;
    padding-bottom: 0;
    margin-bottom: 0;
    &.xxl {
        line-height: 58px;
        font-size: 40pt;
    }
    &.xl {
        line-height: 58px;
        font-size: 40pt;
    }
    &.very_large {
        line-height: 60px;
        font-size: 44pt;
    }
    &.large {
        line-height: 64px;
        font-size: 48pt;
    }
}

/*@media print {
    footer {
        position: fixed;
        bottom: 0;
    }

    .content-block, p {
        page-break-inside: avoid;
    }

    html, body {
        width: 210mm !important;
        background-color: white !important;
    }
}*/
.sticky{

}
</style>
